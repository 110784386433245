import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PageHeader from "../components/PageHeader";
import arrowIcon from "../img/arrow-icon.png";

export const ResourcesPageTemplate = ({
  title,
  content,
  contentComponent,
  intro,
  process,
  tiles
}) => {
  const PageContent = contentComponent || Content;

  return (
    <Fragment>
      <PageHeader title={title} paintColor={"green"} />
      <section className="">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="">
                <section className="section">
                  <div className="content">
                    <div className="has-text-centered">
                      <h1 className="is-size-2 title-underline">
                        {intro.heading}
                      </h1>
                    </div>
                    <div className="content">
                      <p className="is-size-5">{intro.description}</p>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <img src={arrowIcon} alt="Arrow Icon" width="100px" />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-vcentered is-gapless">
            {tiles.map((resource, i) => (
              <Fragment>
                {i % 2 == 0 ? (
                  <Fragment>
                    {console.log(i)}
                    <div className="column is-6 is-flex">
                      <img src={resource.image.childImageSharp.fluid.src} />
                    </div>
                    <div className="column is-6 content">
                      <div className="resource-box-content">
                        <h3 className="is-size-3">{resource.heading}</h3>
                        <p className="is-size-5">{resource.description}</p>
                        <Link to={resource.linkTo} className="btn btn-outline">
                          {resource.linkText}
                        </Link>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="column is-6 content">
                      <div className="resource-box-content">
                        <h3 className="is-size-3">{resource.heading}</h3>
                        <p className="is-size-5">{resource.description}</p>
                        <Link to={resource.linkTo} className="btn btn-outline">
                          {resource.linkText}
                        </Link>
                      </div>
                    </div>
                    <div className="column is-6 is-flex">
                      <img src={resource.image.childImageSharp.fluid.src} />
                    </div>
                  </Fragment>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </section>

      <div className="has-background-primary has-text-white section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="columns is-vcentered">
                <div className="column is-8">
                  <h2 className="is-size-2">Let's Talk About Your Project</h2>
                </div>
                <div className="column">
                  <Link to="/" className="btn btn-white">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ResourcesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const ResourcesPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ResourcesPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        intro={post.frontmatter.intro}
        tiles={post.frontmatter.tiles}
      />
    </Layout>
  );
};

ResourcesPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ResourcesPage;

export const resourcesPageQuery = graphql`
  query ResourcesPage {
    markdownRemark(frontmatter: { templateKey: { eq: "resources-page" } }) {
      html
      frontmatter {
        title
        intro {
          heading
          description
        }
        tiles {
          heading
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          linkText
          linkTo
        }
      }
    }
  }
`;
