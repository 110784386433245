import React from "react";
import pinkBG from "../img/PageHeader/pink-bg.png";
import purpleBG from "../img/PageHeader/purple-bg.png";
import greenBG from "../img/PageHeader/green-bg.png";
import blueBG from "../img/PageHeader/blue-bg.png";

// import PropTypes from "prop-types";
// import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const PageHeader = ({ title, paintColor }) => {
  const bg =
    paintColor === "pink"
      ? pinkBG
      : paintColor === "purple"
      ? purpleBG
      : paintColor === "green"
      ? greenBG
      : paintColor === "blue"
      ? blueBG
      : pinkBG;

  return (
    <section
      className="PageHeader"
      style={{
        backgroundImage: `url(${bg})`
      }}
    >
      <h1 className="PageHeader__title">{title}</h1>
    </section>
  );
};

// PageHeader.propTypes = {
//   gridItems: PropTypes.arrayOf(
//     PropTypes.shape({
//       image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//       text: PropTypes.string
//     })
//   )
// };

export default PageHeader;
